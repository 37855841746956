import logo from 'public/logo.svg'
import login from 'assets/imgs/login.svg'
import loginLight from 'assets/imgs/login-light.svg'
import Image from 'next/image'
import { useForm } from 'react-hook-form'
import { Button, Link } from 'components'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'
import { userLogin } from 'services/auth-service'
import { useTheme } from 'contexts/theme-context'
import { useState } from 'react'
import { useAuth } from 'contexts/auth-context'
import {
  fadeIn,
  fadeInRight,
  fadeInUp,
  stagger6
} from 'public/animation/motion'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_BASE_ORGANIZATION } from 'queries/organization'
import { useLocalStorage } from 'hooks/useLocalstorage'
import { Guards } from 'util/enums'
import { Validator } from 'util/validator'
import { paths } from 'config/paths'
import { useHandleError } from 'hooks/useHandleError'
import { LockClosedIcon } from '@heroicons/react/solid'
// import useABTest from 'hooks/useAbTest'

export default function Login() {
  const { handleSignIn } = useAuth()
  const { t } = useTranslation()
  const router = useRouter()
  const { theme } = useTheme()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [organization] = useLocalStorage('organization', '')

  const { handleError } = useHandleError()
  // const selectedItem = useABTest(
  //   {
  //     loginPage: 50,
  //     headerBanner: 50
  //   },
  //   'referral_g2Review'
  // )
  const [fetchOrganization] = useLazyQuery(GET_USER_BASE_ORGANIZATION, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data?.organization[0].pathname) {
        router.push(`org/${data?.organization[0].pathname}/disclaimers`)
      } else {
        router.push(`organizations`)
      }
    }
  })

  const { register, handleSubmit } = useForm()

  async function onSubmit(data: any) {
    if (loadingButton) {
      return
    }
    setLoadingButton(true)
    if (data.password.length < 8 || !Validator.isValidEmail(data.email)) {
      handleError(t('requestResponse.incorrect username or password'))
      setLoadingButton(false)
      return
    }
    const result = (await userLogin(data.email, data.password)).data
    if (!result.success) {
      handleError(result.message)
      setLoadingButton(false)
    } else {
      setLoadingButton(false)
      handleSignIn(result.data.idToken)
      if (!organization) {
        fetchOrganization()
      } else {
        router.push(`org/${organization}/disclaimers`)
      }
    }
  }

  return (
    <div className="min-h-screen flex items-center ">
      <div className="hidden md:flex bg-primary w-1/2 dark:bg-gray h-screen  justify-center items-center">
        <motion.div
          initial="initial"
          animate="animate"
          variants={fadeInUp}
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
          className="max-w-4xl px-10"
        >
          {/* {selectedItem === 'loginPage' && i18next.language.includes('pt') ? ( */}
          {/* <>
            <Image
              width={300}
              height={300}
              src={BannerG2}
              alt="Banner g2"
              className="rounded-md "
            ></Image>
            <H1 className="text-[45px] mt-10 max-w-[500px] !font-bold">
              {t('login.g2.title')}
            </H1>
            <H3 className="text-[20px] max-w-[500px]">
              {t('login.g2.description')}
            </H3>
            <div
              className="flex items-center gap-2 underline mt-8 cursor-pointer"
              onClick={() => {
                try {
                  window?.clarity('set', 'referral_g2Review', 'loginPage')
                } catch (error) {
                  console.log('Error setting clarity - Error message:', error)
                }

                
                event(GaEvents.ButtonClick, {
                  event_category: 'view',
                  component: 'referral_g2Review',
                  id: 'loginPage'
                })
                
              }}
            >
              {t('login.g2.termsAndCondition')} <RiExternalLinkLine />
            </div>
          </> */}
          {theme === 'dark' ? (
            <Image src={login} alt="Picture of the author" />
          ) : (
            <Image src={loginLight} alt="Picture of the author" />
          )}
        </motion.div>
      </div>
      <div className="h-screen w-full md:w-1/2 dark:bg-black flex justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <motion.div
            initial="initial"
            animate="animate"
            variants={fadeInRight}
            exit={{ opacity: 0, transition: { duration: 0.3 } }}
            className=" w-96 shadow-md border border-gray-200 border-opacity-25 rounded-md dark:bg-gray dark:border-none dark:border-opacity-0"
          >
            <motion.div
              initial="initial"
              animate="animate"
              variants={stagger6}
              className="py-6 px-8"
            >
              <motion.div variants={fadeInRight} className="w-16 h-16 mx-auto">
                <Image src={logo} alt="Logo Adopt" />
              </motion.div>

              <motion.div
                variants={fadeInRight}
                className="mt-2 text-center font-bold text-primary"
              >
                {t('login.welcomeText')}
              </motion.div>

              <motion.div variants={fadeInRight}>
                <div className="mt-6 mb-1 dark:text-white">
                  {t('login.email')}
                </div>
                <input
                  {...register('email')}
                  id="email-address"
                  data-test-id="email"
                  name="email"
                  className="dark:autocompleteInput  dark:bg-gray dark:text-white appearance-none relative block w-full px-3 py-2 border border-gray-400 placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  placeholder={t('login.emailPlaceholder')}
                />
              </motion.div>
              <motion.div variants={fadeInRight}>
                <div className="mt-6 mb-1 dark:text-white">
                  {t('login.password')}
                </div>
                <input
                  {...register('password')}
                  id="password"
                  data-test-id="password"
                  type="password"
                  className="dark:bg-gray dark:text-white appearance-none relative block w-full px-3 py-2 border border-gray-400 placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  placeholder={t('login.passwordPlaceholder')}
                />
              </motion.div>
              <motion.div variants={fadeInRight}>
                <Button
                  iconLeft={LockClosedIcon}
                  loading={loadingButton}
                  className="w-full mt-8"
                  data-test-id="loginButton"
                >
                  {t('login.signIn')}
                </Button>
                <div className="flex justify-around items-center mt-4 font-bold ">
                  <Link href={paths.signup.register}>
                    <div className="text-primary text-sm no-underline">
                      {t('login.createAccount')}
                    </div>
                  </Link>
                  <div className="w-px h-4 bg-primary"></div>
                  <Link href={paths.passwordRecovery}>
                    <div className="hover:text-gray-400 no-underline text-gray-400 text-sm">
                      {t('login.recoverPassword')}
                    </div>
                  </Link>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
          <Link href={paths.sso.login}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeIn(0.4)}
              className="mt-4 hover:text-gray-400 text-center no-underline text-gray-400 text-sm"
            >
              {t('login.loginWithSso')}
            </motion.div>
          </Link>
        </form>
      </div>
    </div>
  )
}

Login.guard = Guards.OnlyUnauthorized
